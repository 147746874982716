import React, { useEffect } from "react";
import { UnsubscribeIcon } from "../../components/Svg";
import "./UnsubscribeScreen.css";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Fab,
  Modal,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { preferenceUpdate } from "../../redux/loginSlice";
import { CheckCircleOutline, Home } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { getOtpApi, postPreferencesApi } from "../../api/loginApi";
import { validateOtpApi } from "../../api/commonApi";
import ToastAlert from "../../components/common/ToastAlert";
import HomeScreenHeader from "../Home/components/HomeScreenHeader";

function UnsubscribeScreen() {
  const navigate = useNavigate();
  const loginSlice = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const CC = [
    { code: "+1", country: "United States" },
    { code: "+91", country: "India" },
  ];
  const [otp, setOtp] = useState("");
  const [otpMatch, setOtpMatch] = useState(false);
  const [mobileNumber, setMobileNumber] = useState(
    loginSlice.loginState ? loginSlice.phone.slice(-10) : "",
  );
  const [alert, setAlert] = useState("");
  const [validation, setValidation] = useState("");
  const [toast, setToast] = useState("");
  const [submit, setSubmit] = useState(false);

  const [ccState, setCcState] = useState(
    loginSlice.loginState
      ? CC.find((item) => item.code === loginSlice.phone.slice(0, -10))
      : null,
  );
  const [whatsappPromotions, setWhatsappPromotions] = useState(
    loginSlice.user.preferences
      ? loginSlice.user.preferences.whatsapp_promotions
      : true,
  );

  const [emailPromotions, setEmailPromotions] = useState(
    loginSlice.user.preferences
      ? loginSlice.user.preferences.email_promotions
      : true,
  );
  const [history, setHistory] = useState(
    loginSlice.user.updates ? loginSlice.user.updates : [],
  );
  const [viewHistory, setViewHistory] = useState(false);
  const [changedNumber, setChangedNumber] = useState(true);
  const handlePreferences = async () => {
    const number = ccState.code + mobileNumber;
    const { result, error } = await postPreferencesApi(number, preferences);
    if (result) {
      console.log(result, "first");
      if (result.usercode === loginSlice.phone) {
        dispatch(
          preferenceUpdate({
            preferences: {
              email_promotions: emailPromotions,
              whatsapp_promotions: whatsappPromotions,
              whatsapp_otp: true,
            },
            updates: result.updates,
          }),
        );
        setHistory(result.updates);
      } else {
        console.log(result, "else");
        setEmailPromotions(result.preferences.email_promotions);
        setWhatsappPromotions(result.preferences.whatsapp_promotions);
        setHistory(result.updates);
      }
      setToast("Updated Successfully");
    } else if (error) {
      setAlert("Something went wrong! Please try again later");
    }
  };
  const preferences = {
    email_promotions: emailPromotions,
    whatsapp_promotions: whatsappPromotions,
    whatsapp_otp: true,
  };

  console.log(otpMatch);

  const handleOtp = async () => {
    const { result: otpResult, error: otpError } = await getOtpApi(
      ccState.code,
      mobileNumber,
    );
    if (otpResult) {
      setOtpMatch(true);
    } else if (otpError) {
      console.log(otpError, "err");
    }
  };

  const handleValidate = async () => {
    setValidation("");
    if (!otpMatch) {
      if (ccState && mobileNumber.length === 10) {
        handleOtp();
      } else {
        setAlert("Enter valid mobile number");
      }
    } else {
      if (otp.length === 6) {
        const { result, error } = await validateOtpApi(
          ccState.code,
          mobileNumber,
          otp,
        );
        if (result) {
          console.log(result, "asdads");
          setEmailPromotions(
            result.user.preferences
              ? result.user.preferences.email_promotions
              : true,
          );
          setWhatsappPromotions(
            result.user.preferences
              ? result.user.preferences.whatsapp_promotions
              : true,
          );
          setHistory(result.user.updates);
          setValidation(
            `Validation is successfull for mobile number ${mobileNumber} update your preferences`,
          );
          setChangedNumber(false);
          setOtp("");
          setOtpMatch(false);
        } else if (error) {
          if (error.response.data.error === "Invalid OTP") {
            setAlert("Invalid OTP. Enter valid otp");
          } else {
            setAlert("Something went wrong");
          }
        }
      } else {
        setAlert("Enter 6 digits of OTP");
      }
    }
  };

  const changedNumberHandler = (code, mobileNumber) => {
    if (
      JSON.stringify(code + mobileNumber) === JSON.stringify(loginSlice.phone)
    ) {
      setChangedNumber(true);
      setEmailPromotions(loginSlice.user.preferences.email_promotions);
      setWhatsappPromotions(loginSlice.user.preferences.whatsapp_promotions);
    } else {
      setChangedNumber(false);
    }
  };

  function formatDateTimeToIST(currentTime) {
    const date = new Date(currentTime);

    const options = {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
      second: "2-digit",
      timeZone: "Asia/Kolkata",
    };

    const formattedDate = new Intl.DateTimeFormat("en-GB", options).format(
      date,
    );

    const formattedDateTime = formattedDate.replace(",", "");
    return formattedDateTime;
  }

  useEffect(() => {
    if (loginSlice.phone === ccState?.code + mobileNumber) {
      setEmailPromotions(
        loginSlice.user.preferences
          ? loginSlice.user.preferences.email_promotions
          : true,
      );
      setWhatsappPromotions(
        loginSlice.user.preferences
          ? loginSlice.user.preferences.whatsapp_promotions
          : true,
      );
      setHistory(loginSlice.user.updates);
      setSubmit(true);
    } else {
    }
  }, [mobileNumber, ccState]);
  const TableRowStyled = styled(TableRow)`
    &:nth-of-type(odd) {
      background-color: #ffffff;
    }
    &:nth-of-type(even) {
      background-color: #eaecf0;
    }
  `;
  return (
    <div className="justify-center mx-auto flex flex-1 flex-col text-center font-montserrat">
      <HomeScreenHeader />
      <div className="flex flex-col items-center align-middle mt-10">
        <UnsubscribeIcon />
        <p className="text-5xl font-medium italic text-[#51A175]">
          <span className="text-black">
            Update your communication preferences
          </span>
        </p>
        {/* <p className="my-4 text-xl">
          Please select what you want to unsubscribe and click the button below
          to confirm
        </p> */}
        {alert && <Alert severity="error">{alert}</Alert>}
        {validation && <Alert severity="success">{validation}</Alert>}
        <div className="my-10 flex flex-col space-y-5">
          <div className="flex">
            <Autocomplete
              options={CC}
              disableClearable
              value={ccState}
              onChange={(event, newValue) => {
                setCcState(newValue);
                changedNumberHandler(newValue.code, mobileNumber);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.code}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="CC"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            <TextField
              type="number"
              id="outlined-basic"
              label="Mobile Number"
              variant="outlined"
              value={mobileNumber}
              onChange={(e) => {
                const input = e.target.value;
                setAlert("");
                setValidation("");
                setOtpMatch(false);
                if (input.length <= 10) {
                  setMobileNumber(input);
                }
                changedNumberHandler(ccState.code, input);
              }}
            />
          </div>
          {otpMatch && (
            <TextField
              sx={{ marginBottom: "15px" }}
              type="number"
              id="outlined-basic"
              label="OTP"
              variant="outlined"
              value={otp}
              onChange={(e) => {
                const input = e.target.value;
                setAlert("");
                setValidation("");
                if (input.length <= 6) {
                  setOtp(input);
                }
              }}
              inputProps={{ maxLength: 10 }}
            />
          )}
          {!changedNumber && (
            <Button
              onClick={handleValidate}
              sx={{
                width: "100%",
                py: "15px",
                background: "#51a175",
                ":hover": {
                  backgroundColor: "#1f6d42",
                },
              }}
            >
              <p className="button_us">{otpMatch ? "Validate" : "Get OTP"}</p>
            </Button>
          )}
          {otpMatch && (
            <Button
              onClick={handleOtp}
              sx={{
                width: "100%",
                py: "15px",
                background: "#51a175",
                ":hover": {
                  backgroundColor: "#1f6d42",
                },
              }}
            >
              <p className="button_us">Resend OTP</p>
            </Button>
          )}
          {(loginSlice.phone === ccState?.code + mobileNumber ||
            validation) && (
            <>
              <p className="text-left">WhatsApp Preferences</p>
              <div className="mb-4 flex items-center ml-10">
                <input
                  checked={whatsappPromotions}
                  id="whatsapp-checkbox"
                  onChange={() => {
                    setWhatsappPromotions(!whatsappPromotions);
                  }}
                  type="checkbox"
                  value=""
                  className="h-5 w-5 rounded border-gray-300 bg-gray-100 accent-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                />
                <label
                  for="whatsapp-checkbox"
                  className="ms-2 select-none text-sm font-medium"
                >
                  Promotions
                </label>
              </div>
              <div className="mb-4 flex items-center ml-10">
                <input
                  disabled
                  checked
                  id="default-checkbox"
                  type="checkbox"
                  className="h-5 w-5 rounded border-gray-300 bg-gray-100 accent-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                />
                <label
                  for="checked-checkbox"
                  className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Order notifications (mandatory)
                </label>
              </div>
              <p className="text-left">Email Preferences</p>
              <div className="mb-4 flex items-center ml-10">
                <input
                  checked={emailPromotions}
                  id="email-checkbox"
                  onChange={() => {
                    setEmailPromotions(!emailPromotions);
                  }}
                  type="checkbox"
                  className="h-5 w-5 rounded border-gray-300 bg-gray-100 accent-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                />
                <label
                  for="email-checkbox"
                  className="ms-2 select-none text-sm font-medium"
                >
                  Promotions
                </label>
              </div>
              <div className="mb-4 flex items-center ml-10">
                <input
                  disabled
                  checked
                  id="default-checkbox"
                  type="checkbox"
                  value=""
                  className="h-5 w-5 rounded border-gray-300 bg-gray-100 accent-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                />
                <label
                  for="checked-checkbox"
                  className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Order notifications (mandatory)
                </label>
              </div>
            </>
          )}

          {(loginSlice.phone === ccState?.code + mobileNumber ||
            validation) && (
            <div>
              <Button
                onClick={handlePreferences}
                sx={{
                  width: "100%",
                  py: "15px",
                  background: "#51a175",
                  ":hover": {
                    backgroundColor: "#1f6d42",
                  },
                }}
              >
                <p className="button_us">Submit</p>
              </Button>
              {/* <button className="w-full rounded bg-[#51a175] px-4 py-4 font-semibold text-white hover:bg-blue-700">
                Submit
              </button> */}
            </div>
          )}
          <Button
            onClick={() => {
              setViewHistory(true);
            }}
            sx={{
              width: "100%",
              py: "15px",
              background: "#51a175",
              ":hover": {
                backgroundColor: "#1f6d42",
              },
            }}
          >
            <p className="button_us">View history</p>
          </Button>
        </div>
      </div>
      <Modal
        open={viewHistory}
        onClose={() => {
          setViewHistory(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", sm: "80%", md: "60%", lg: "50%" },
            bgcolor: "#ffffff",
            boxShadow: "0px 1px 2px 0px #0000000F",
            padding: "24px",
            borderRadius: "13px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Preference history
          </Typography>
          {history.length !== 0 ? (
            <TableContainer style={{ maxHeight: "550px", overflowX: "scroll" }}>
              <Table aria-label="sticky table">
                <TableHead
                  style={{
                    height: "44px",
                    borderRadius: "100px",
                  }}
                >
                  <TableRow>
                    <TableCell
                      sx={{ padding: 0, textAlign: "center" }}
                      align="left"
                    >
                      <h1 className="w-16">S.no</h1>
                    </TableCell>
                    <TableCell
                      sx={{ padding: 0, textAlign: "center" }}
                      align="left"
                    >
                      <h1 className="w-40">Whatsapp promotions</h1>
                    </TableCell>
                    <TableCell
                      sx={{ padding: 0, textAlign: "center" }}
                      align="left"
                    >
                      <h1 className="w-40">Email promotions</h1>
                    </TableCell>
                    <TableCell
                      sx={{ padding: 0, textAlign: "center" }}
                      align="left"
                    >
                      <h1 className="w-40">Updated Time</h1>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {history.map((item, index) => {
                    formatDateTimeToIST(item.createdtimestamp);
                    return (
                      <TableRowStyled key={index} sx={{ height: "40px" }}>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ padding: 0, textAlign: "center" }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ padding: 0, textAlign: "center" }}
                        >
                          {item.activitylog.preferences.whatsapp_promotions
                            ? "Enabled"
                            : "Disabled"}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ padding: 0, textAlign: "center" }}
                        >
                          {item.activitylog.preferences.email_promotions
                            ? "Enabled"
                            : "Disabled"}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ padding: 0, textAlign: "center" }}
                        >
                          {formatDateTimeToIST(item.createdtimestamp)}
                        </TableCell>
                      </TableRowStyled>
                    );
                  })}
                </TableBody>
              </Table>

              {/* <TableFooter style={{ position: 'sticky' }}>

        </TableFooter> */}
            </TableContainer>
          ) : (
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {`No updates yet.`}
            </Typography>
          )}
        </Box>
      </Modal>
      <ToastAlert
        alert={toast}
        handleClose={() => setToast("")}
        autoHideDuration={2000}
        severity={"success"}
      />
    </div>
  );
}

export default UnsubscribeScreen;
