import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./LoginScreen.css";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Fab,
  Modal,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { login } from "../../redux/loginSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { getOtpApi, isNewUser, postLoginApi } from "../../api/loginApi";
import { CartOCartLogo } from "../../components/Svg";
import Title from "../../components/common/Title";
import ToastAlert from "../../components/common/ToastAlert";
import { CheckCircleOutline, Home, NotInterested } from "@mui/icons-material";
import HomeScreenHeader from "../Home/components/HomeScreenHeader";

const LoginScreen = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [otpVisible, setOtpVisible] = useState(false);
  const [alert, setAlert] = useState("");
  const [ccState, setCcState] = useState(null);
  const [newUserAlert, setNewUserAlert] = useState(false);
  const dispatch = useDispatch();
  const loginredux = useSelector((state) => state.login);
  const CC = [
    { code: "+1", country: "United States" },
    { code: "+91", country: "India" },
  ];
  const number = ccState?.code + mobileNumber;

  const handleLogin = async () => {
    if (otpVisible) {
      const { result, error } = await postLoginApi(
        ccState.code,
        mobileNumber,
        otp,
      );
      if (result) {
        dispatch(login(result));
        setAlert("");
        navigate(-1);
      } else if (error) {
        if (error.response.data.error === "Invalid OTP") {
          setAlert("Invalid OTP. Enter valid otp");
        } else {
          setAlert("Something went wrong");
        }
      }
    } else {
      if (mobileNumber.length < 10 || isNaN(mobileNumber) || !ccState) {
        setAlert("Enter valid mobile number");
      } else {
        const { result: newUserResult, error: newUserError } =
          await isNewUser(number);
        if (newUserResult) {
          if (newUserResult.isnewuser) {
            setNewUserAlert(true);
          } else if (!newUserResult.isnewuser) {
            handleOtp();
          }
        } else if (newUserError) {
          console.log(newUserError);
          setAlert("Something went wrong! Please try again later");
        }
      }
    }
  };

  const handleOtp = async () => {
    const { result: otpResult, error: otpError } = await getOtpApi(
      ccState.code,
      mobileNumber,
    );
    if (otpResult) {
      setOtpVisible(true);
    } else if (otpError) {
      setAlert("Error fetching otp! Try ");
    }
  };
  console.log(ccState);

  return (
    <div className="h-screen items-center justify-center flex-col">
      {/* <Fab
        sx={{
          position: "absolute",
          top: 50,
          left: 50,
        }}
        color="success"
        aria-label="add"
        onClick={() => {
          navigate("/");
        }}
      >
        <Home />
      </Fab> */}
      <HomeScreenHeader />
      <div className="flex flex-col align-middle items-center justify-around rounded-3xl p-8 space-y-8 ">
        <Title />
        {!otpVisible ? (
          <div className="flex flex-row text-white">
            <Autocomplete
              options={CC}
              value={ccState}
              onChange={(event, newValue) => {
                setCcState(newValue);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.code}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="CC"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            <TextField
              type="number"
              id="outlined-basic"
              label="Mobile Number"
              variant="outlined"
              value={mobileNumber}
              onChange={(e) => {
                const input = e.target.value;
                if (input.length <= 10) {
                  setMobileNumber(input);
                  setAlert("");
                }
              }}
            />
          </div>
        ) : (
          <div className="number_edit space-x-5 ">
            <div className="flex flex-col">
              <p className="details">Mobile Number</p>
              <p className="details">{ccState.code + mobileNumber}</p>
              {/* <p className="details">Otp</p>
              <p className="details">{otpMatch}</p> */}
            </div>

            <Button
              onClick={() => {
                setOtpVisible(false);
              }}
            >
              <EditIcon
                style={{
                  width: "20px",
                  height: "20px",
                }}
              />
            </Button>
          </div>
        )}
        {otpVisible && (
          <TextField
            sx={{ marginBottom: "15px" }}
            type="number"
            id="outlined-basic"
            label="OTP"
            variant="outlined"
            value={otp}
            onChange={(e) => {
              const input = e.target.value;
              if (input.length <= 6) {
                setOtp(input);
                setAlert("");
              }
            }}
            inputProps={{ maxLength: 10 }}
          />
        )}
        <Button
          variant="contained"
          onClick={handleLogin}
          sx={{ minWidth: "200px" }}
        >
          {!otpVisible ? "Get OTP" : "Login"}
        </Button>
        {otpVisible && (
          <Button
            variant="contained"
            onClick={handleOtp}
            sx={{ minWidth: "200px" }}
          >
            Resend OTP
          </Button>
        )}

        <Alert severity="info" sx={{}}>
          To recieve otp add "+1(415)523-8886" in your contact, and send "join
          see-proud" in whatsapp to receive the OTP.
        </Alert>
        <ToastAlert
          alert={alert}
          handleClose={() => setAlert("")}
          autoHideDuration={2000}
          severity={"error"}
        />
        <Modal
          open={newUserAlert}
          onClose={() => {
            setNewUserAlert(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              minWidth: "250px",
              bgcolor: "#ffffff",
              boxShadow: "0px 1px 2px 0px #0000000F",
              padding: "24px",
              borderRadius: "13px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              New User?
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {`By accepting, you consent to receive order updates, notifications, and other communications from us through WhatsApp. 
              \nThese messages may include important information regarding your orders, as well as promotional content and customer service updates. 
              \nYou can view, opt-in and opt-out your communication preferences at any time through your account settings.`}
            </Typography>
            <div className="flex flex-row-reverse space-x-4 space-x-reverse">
              <Button
                variant="contained"
                sx={{ marginTop: "24px", backgroundColor: "#51A175" }}
                onClick={async () => {
                  setNewUserAlert(false);
                  setOtpVisible(true);
                  await handleOtp();
                }}
              >
                <CheckCircleOutline
                  fontSize="24"
                  sx={{
                    marginRight: "13px",
                    width: 20,
                    height: 20,
                    maxRows: "8px",
                    color: "#",
                  }}
                />
                Accept
              </Button>
              <Button
                variant="contained"
                sx={{ marginTop: "24px", backgroundColor: "#" }}
                onClick={() => {
                  setNewUserAlert(false);
                }}
              >
                <NotInterested
                  fontSize="24"
                  sx={{
                    marginRight: "13px",
                    width: 20,
                    height: 20,
                    maxRows: "8px",
                    color: "#",
                  }}
                />
                Cancel
              </Button>
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default LoginScreen;
