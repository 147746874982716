import React from "react";
import "./PrivacyPolicy.css";
import HomeScreenHeader from "../Home/components/HomeScreenHeader";

const PrivacyPolicy = () => {
  return (
    <div className=" justify-center mx-auto flex flex-1 flex-col font-montserrat custom-heading-styles">
      <HomeScreenHeader />
      <section className="privacy">
        <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
          <div className="title text-center text-5xl font-medium">
            <p>Privacy Policy</p>
            <hr />
          </div>
          <div className="p-6 max-w-4xl mx-auto">
            {/* <h3 className="text-xl font-bold mt-6 mb-2">Effective Date: [Date]</h3> */}
            <p>
              At Jasmine eCommerce Solutions ("we," "our," "us"), we are
              committed to protecting your privacy and ensuring that your
              personal information is handled in a safe and responsible manner.
              This Privacy Policy explains how we collect, use, disclose, and
              safeguard your information when you use our CartOCart mobile
              application (the "App") and related services (collectively, the
              "Services"). By using the App, you agree to the collection and use
              of information in accordance with this policy.
            </p>
            <h2 className="text-2xl font-bold mt-6 mb-2">
              1. Information We Collect
            </h2>
            <h3 className="text-xl font-bold mt-6 mb-2">1.1 Personal Information</h3>
            <p>
              We may collect personal information that you provide to us
              directly, including but not limited to:
            </p>
            <ul className="list-disc pl-5">
              <li className="mb-2">
                Contact Information: Name, email address, phone number, and
                shipping address.
              </li>
              <li className="mb-2">Account Information: Username and account preferences.</li>
              {/* <li className="mb-2">
                Payment Information: Credit card details or other payment
                methods used for transactions.
              </li> */}
            </ul>

            <h3 className="text-xl font-bold mt-6 mb-2">1.2 Usage Data</h3>
            <p>
              We may collect information about how you use the App, including:
            </p>
            <ul className="list-disc pl-5">
              <li className="mb-2">
                Device Information: Device type, operating system, and unique
                device identifiers.
              </li>
              <li className="mb-2">
                Log Data: IP address, browser type, access times, and referring
                site information.
              </li>
              <li className="mb-2">
                Usage Activity: Interaction with App features, order history,
                and search queries.
              </li>
            </ul>
            <h3 className="text-xl font-bold mt-6 mb-2">1.3 Location Data</h3>
            <p>
              If you enable location services, we may collect your location
              information to facilitate delivery or provide location-based
              services.
            </p>
            <h2 className="text-2xl font-bold mt-6 mb-2">
              2. How We Use Your Information
            </h2>
            <p>
              We may use the collected information for various purposes,
              including:
            </p>
            <ul className="list-disc pl-5">
              <li className="mb-2">
                To Provide and Improve Services: Process orders, manage
                accounts, and enhance user experience.
              </li>
              <li className="mb-2">
                To Communicate with You: Send order confirmations, updates,
                promotional offers, and customer support messages.
              </li>
              {/* <li className="mb-2">
                To Process Payments: Handle transactions and prevent fraudulent
                activities.
              </li> */}
              <li className="mb-2">
                To Analyze Usage: Understand how users interact with the App to
                improve functionality and user experience.
              </li>
            </ul>
            {/*             <h2 className="text-2xl font-bold mt-6 mb-2">3. How We Share Your Information</h2>
            <p>We may share your information in the following circumstances:</p>
            <ul className="list-disc pl-5">
              <li className="mb-2">
                With Service Providers: We may share information with
                third-party service providers who assist us in operating the
                App, processing payments, and delivering orders.
              </li>
              <li className="mb-2">
                For Legal Reasons: We may disclose information if required by
                law or to respond to legal requests, enforce our policies, or
                protect our rights, property, or safety.
              </li>
              <li className="mb-2">
                In Business Transfers: In the event of a merger, acquisition, or
                asset sale, your information may be transferred as part of the
                transaction.
              </li>
            </ul> */}
            <h2 className="text-2xl font-bold mt-6 mb-2">3. Data Security</h2>
            <p>
              We implement reasonable security measures to protect your personal
              information from unauthorized access, disclosure, alteration, and
              destruction. However, no method of transmission over the internet
              or electronic storage is 100% secure, and we cannot guarantee
              absolute security.
            </p>
            <h2 className="text-2xl font-bold mt-6 mb-2">4. Your Choices</h2>
            <h3 className="text-xl font-bold mt-6 mb-2">4.1 Access and Update</h3>
            <p>
              You may access and update your account information by logging into
              your account on the App.
            </p>
            <h3 className="text-xl font-bold mt-6 mb-2">4.2 Marketing Communications</h3>
            <p>
              You can opt-out of receiving promotional emails or notifications
              by following the unsubscribe instructions provided in those
              communications.
            </p>
            <h3 className="text-xl font-bold mt-6 mb-2">4.3 Location Data</h3>
            <p>
              You can disable location services on your device if you do not
              wish to share your location with us.
            </p>
            {/*             <h2 className="text-2xl font-bold mt-6 mb-2">5. Children’s Privacy</h2>
            <p>
              The App is not intended for use by children under the age of 13.
              We do not knowingly collect personal information from children
              under 13. If we become aware that we have collected such
              information, we will take steps to delete it.
            </p> */}
            <h2 className="text-2xl font-bold mt-6 mb-2">
              5. Changes to This Privacy Policy
            </h2>
            <p>
              We may update this Privacy Policy from time to time. We will
              notify you of significant changes by posting the updated policy on
              the App or via email. Your continued use of the App after any
              changes indicates your acceptance of the updated policy.
            </p>
            <h2 className="text-2xl font-bold mt-6 mb-2">6. Contact Us</h2>
            <p>
              If you have any questions or concerns about this Privacy Policy or
              our data practices, please contact us at:
            </p>
            <p>
              Email:{" "}
              <a href="mailto:jasmecomsol@gmail.com">jasmecomsol@gmail.com</a>
            </p>
            <p>Jasmine eCommerce Solutions</p>
            <p>
              <a href="https://jasminegrocery.com">
                https://jasminegrocery.com
              </a>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
